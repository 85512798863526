import he from "he";
import { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";

type FlashMessage = {
  message: string;
  level: string;
};

const FlashMessages = () => {
  const [flashMessages, setFlashMessages] = useState<FlashMessage[]>();

  useEffect(() => {
    if (window.__MAZA__.flashMessages) {
      setFlashMessages(window.__MAZA__.flashMessages);
    }
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setFlashMessages(undefined);
    }, 10000);
    return () => clearTimeout(timer);
  }, []);

  const decodedMessages = flashMessages?.map((message) => {
    return {
      ...message,
      message: he.decode(message.message),
    };
  });

  return (
    <>
      {decodedMessages && (
        <div className="w-full px-4">
          <div className="w-160 flex justify-end py-2 ">
            {decodedMessages.map(({ message, level }, key) => (
              <div className={`alert ${level} w-auto`} key={message}>
                <span dangerouslySetInnerHTML={{ __html: message }} />
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

const domNode = document.getElementById("flashMessagesContainer");

if (domNode) {
  const root = createRoot(domNode);
  root.render(<FlashMessages />);
} else {
  console.error("Unable to find domNode or csrfToken");
}
